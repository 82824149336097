a{
    text-decoration: none;
    cursor: pointer;
}

/******/


.nav-set{
    font-size: 1.05rem;

    /*
    border: 1px solid rgba(0, 0, 0, 0.288);
    border-radius: 0.5rem;
    background: rgb(221, 221, 221);;
    */
}

/*
.nav-set:hover{
    border: 1px solid rgb(35, 26, 136, 0.288);;
}
*/

.nav-set a{
    margin-left: 1rem;
    cursor: pointer;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.185);
    border-radius: 0.75rem;
}

.navset-b a{
    margin-left: 0.5rem;
    cursor: pointer;
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.185);
    border-radius: 0.75rem;
} 


/******/



/******************/


/*DOWNLOAD-CV*/
/* Modified code*/

.button {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    padding: 0.2em 2em;
    font-size: 17px;
    background: #284b63;
    border: none;
    position: relative;
    z-index: 1;
    border-radius: 50px;
    transition: all 0.3s;   
}

a.button {
color: rgb(209, 209, 209);
}

a.button:hover{
    background: #3a6ea5;
    color: white;

}




/******** Social *******/

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}

.social-links:hover {
    transform: scale(1.5);
}

.social a {
    color: black;
}

.social a.twetter i {
    border-radius: 50%;
}

.social a.linkedin:hover i {
    color: #0a66c2;
    transition: color 0.1s;
}

.social a.instagram:hover i {
    color: #FC00C5;
    transition: color 0.1s;
}

.social a.twetter:hover i {
    color: #1d9bf0;
    transition: color 0.1s;
}

.social a.github:hover i {
    color: #303182;
    transition: color 0.1s;
}

/**/

.social a.youtube:hover i {
    color: #ff0000;
    transition: color 0.1s;
}

.social a.spotify:hover i {
    color: #1ed760;
    transition: color 0.1s;
}

/****Downİcon****/
.down-ic img {
    max-width: 2rem;
    max-height: 2rem;
}

/***** About Me *****/

.contact-ali{
    color: #4cc9f0;
}

.contact-ali:hover{
    color: #00a6fb;
}


/***** Skills *******/

:root{
    --skillBoxHover: #495057;
}

.skill-box {
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.253);
    border-radius: 2rem;
    transform: scale(0.9);
    background: #212529;
}

.skill-css:hover {
    color: #2862E9;
    font-weight: bold;
    transform: scale(1);
    background: var(--skillBoxHover);
    text-shadow: 2px 2px #000000;
}

.skill-js:hover {
    color: #EFD81D;
    font-weight: bold;
    transform: scale(1);
    background: var(--skillBoxHover);
    text-shadow: 2px 2px #000000;
}

.skill-html:hover {
    color: #E96228;
    font-weight: bold;
    transform: scale(1);
    background: var(--skillBoxHover);
    text-shadow: 2px 2px #000000;
}

.c-sp-ft {
    font-family: specialCSharpFont;
    margin: 0;
    padding: 0;
}
.skill-cs:hover {
    color: #7b3399;
    font-weight: bold;
    transform: scale(1);
    background: var(--skillBoxHover);
    text-shadow: 2px 2px #000000;
}


.skill-react:hover {
    color: #60dfff;
    font-weight: bold;
    transform: scale(1);
    background: var(--skillBoxHover);
    text-shadow: 2px 2px #000000;
}