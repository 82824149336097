:root{
    --pageText: whitesmoke;
}

a{
    color: whitesmoke;
}
.social i{
    color: #dee2e6;
}

/*********MAİN COLOR***********/

#myheader {
    backdrop-filter: blur(5px);
    color: var(--pageText);
}

#navbar {
    background: #212529;
    color: var(--pageText);
}

#home {
    background: #343a40;
    color: var(--pageText);
}

#aboutMe {
    background: #32373d;
    color: var(--pageText);
}

#Skills {
    background: #343a40;
    color: var(--pageText);
}

#footer{
    background: #24282c;
    color: var(--pageText);
}


/********* NAVBAR ***********/

/*isVisiable ile yapılacak*/
.nav-set a:hover, .navset-b a:hover{
    color: rgb(176, 214, 219);
    background: #282d31;
}

.Nav-Ali{
    color: whitesmoke;
}

/********* Text Color **********/

.pageTittle{
    color: #bfdbf7;
}