.tittle-shadow{
    text-shadow: 2px 2px #acacac
}

#navbar a:hover {
    transform: scale(1.05);
}

/******* ScrollBar *********/

::-webkit-scrollbar {
    width: 10px;
    background-color: #eaeaea;
    border-left: 1px solid #cecece;
}

::-webkit-scrollbar-thumb {
    background-color: #003566;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #023e7d;
}

/***/

header {
    position: fixed;
    backdrop-filter: blur(5px);
    z-index: 999;
    top: 0;
}

/*PROFILE PHOTO*/

.profile-set {
    justify-content: center;
}

.department {
    margin-top: 2rem;
    opacity: 0.8;
    text-align: center;
}

.profile-photo {
    border-radius: 50%;
    box-sizing: border-box;
    width: 16rem;
    height: 16rem;
    z-index: 3;
}

/********* FOOTER **********/
.footer-set {
    padding: 0.75rem 1.5rem;
}

